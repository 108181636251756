import { tests } from "./tests";
import Row from "react-bootstrap/Row";
import ListGroup from "react-bootstrap/ListGroup";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";

function Home() {
  return (
    <Row xl>
      <Card style={{ width: "100%" }}>
        <Card.Header>
          <h2>Select a test</h2>
        </Card.Header>
        <ListGroup variant="flush">
          {tests
            .filter((test) => !test.isTest)
            .map((test) => (
              <Link to={`/test/${test.name}`}>
                <ListGroup.Item action>
                  <h3>{test.name}</h3>
                </ListGroup.Item>
              </Link>
            ))}
        </ListGroup>
      </Card>
    </Row>
  );
}

export default Home;

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { tests } from "./tests";
import { Link, useParams, useRouteMatch } from "react-router-dom";

const TestOverview = () => {
  let { testName } = useParams();
  let match = useRouteMatch();
  const test = tests.find((test) => test.name === testName);

  return (
    <Container fluid>
      <Row>
        <h2>{test.name} Overview</h2>
      </Row>
      <Row>
        <Col>
          <Table striped bordered>
            <thead>
              <tr>
                <th>
                  Sections will appear in the following order during your test:
                </th>
              </tr>
            </thead>
            <tbody>
              {test.sections
                .filter((section) => !section.isBreak)
                .map((section) => {
                  return (
                    <tr>
                      <td>{section.name}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </Col>
        <Col>
          <p align="left">
            When you click the “Begin Test” button the timer will start, so have
            your test, pencil, and calculator ready. Once the test starts, you
            won’t be able to pause the timer, but all section and break times
            are the same as you will experience at your official test. Don’t
            forget to eat before you start and during your break times. Good
            luck!
          </p>
        </Col>
      </Row>

      <Link to={`${match.url}/start`}>
        <Button>Begin Test</Button>
      </Link>
    </Container>
  );
};

export default TestOverview;

import { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { tests } from "./tests";
import { useParams } from "react-router-dom";
import { secondsToDisplay } from "./utils/secondsToDisplay";

const Test = () => {
  let { testName } = useParams();
  const test = tests.find((test) => test.name === testName);
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState(1);
  const currentSection = test.sections[currentSectionIndex];
  const [title, setTitle] = useState(`${test.name}: ${currentSection.name}`);
  const displayTime = secondsToDisplay(timeRemaining);
  const padNumber = (number) => {
    if (number.toString().length === 1) {
      return `0${number}`;
    }
    return number.toString();
  };

  const handleRestTimeTitle = (seconds) => {
    setTitle(
      `There is no break between these sections, next section will start in ${seconds} ${
        seconds === 1 ? "second" : "seconds"
      }`
    );
  };

  useEffect(() => {
    if (currentSection) {
      setTimeRemaining(currentSection.time);
      setTitle(`${test.name}: ${currentSection.name}`);
    }
  }, [currentSection, test.name]);

  useEffect(() => {
    let timeout;
    if (timeRemaining > 0) {
      timeout = setTimeout(() => setTimeRemaining(timeRemaining - 1), 1000);
      document.title = `⏱ ${padNumber(displayTime.hours)}:${padNumber(
        displayTime.minutes
      )}:${padNumber(displayTime.seconds)} | ${test.name}: ${
        currentSection.name
      }`;
    }
    if (
      displayTime.hours === 0 &&
      displayTime.minutes === 5 &&
      displayTime.seconds === 0
    ) {
      var bell3 = new Audio("/audio/bell3.mp3");
      bell3.play();
    }
    if (timeRemaining === 0) {
      var bell5 = new Audio("/audio/bell5.mp3");
      bell5.play();

      if (
        test.sections[currentSectionIndex + 1]?.isBreak ||
        test.sections[currentSectionIndex]?.isBreak
      ) {
        setCurrentSectionIndex(currentSectionIndex + 1);
      } else {
        handleRestTimeTitle(5);
        setTimeout(() => handleRestTimeTitle(4), 1000);
        setTimeout(() => handleRestTimeTitle(3), 2000);
        setTimeout(() => handleRestTimeTitle(2), 3000);
        setTimeout(() => handleRestTimeTitle(1), 4000);
        setTimeout(() => setCurrentSectionIndex(currentSectionIndex + 1), 5000);
      }
    }
    return () => {
      clearTimeout(timeout);
      document.title = "Xcel Timer";
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeRemaining]);

  return (
    <Container fluid>
      {currentSection ? (
        <>
          <Row>
            <h2>{title}</h2>
          </Row>
          <Row>
            <Col>
              <p className="clock" style={{ fontSize: "18vw" }}>
                {padNumber(displayTime.hours)}:{padNumber(displayTime.minutes)}:
                {padNumber(displayTime.seconds)}
              </p>
            </Col>
          </Row>
        </>
      ) : (
        <Row>
          <h2>{test.name} complete!</h2>
          <h3>
            Please take pictures of your bubble sheet and email to{" "}
            <a href="mailto:info@xceladvising.com">info@xceladvising.com</a>
          </h3>
        </Row>
      )}
    </Container>
  );
};

export default Test;

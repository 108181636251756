import "./App.css";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Test from "./Test";
import TestOverview from "./TestOverview";
import Home from "./Home";

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar
          bg="light"
          style={{ marginBottom: 10, borderBottom: "1px solid #e5e5e5" }}
        >
          <Link to="/">
            <Navbar.Brand>
              <h1>Xcel Timer</h1>
            </Navbar.Brand>
          </Link>
        </Navbar>
        <Container>
          <Switch>
            <Route path="/test/:testName/start">
              <Test />
            </Route>
            <Route path="/test/:testName">
              <TestOverview />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </Container>
      </Router>
    </div>
  );
}

export default App;

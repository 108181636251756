export const tests = [
  {
    name: "SAT",
    sections: [
      { name: "Reading", time: 3900 },
      { name: "Break 1", time: 600, isBreak: true },
      { name: "Writing and Language", time: 2100 },
      { name: "Math (No Calculator)", time: 1500 },
      { name: "Break 2", time: 300, isBreak: true },
      { name: "Math (Calculator)", time: 3300 },
      { name: "Break 3", time: 120, isBreak: true },
      { name: "Essay", time: 3000 },
    ],
  },
  {
    name: "ACT",
    sections: [
      { name: "English", time: 2700 },
      { name: "Math", time: 3600 },
      { name: "Break", time: 600, isBreak: true },
      { name: "Reading", time: 2100 },
      { name: "Science", time: 2100 },
      { name: "Break", time: 300, isBreak: true },
      { name: "Essay", time: 2400 },
    ],
  },
  {
    name: "PSAT",
    sections: [
      { name: "Reading", time: 3600 },
      { name: "Break 1", time: 300, isBreak: true },
      { name: "Writing and Language", time: 2100 },
      { name: "Math (No Calculator)", time: 1500 },
      { name: "Break 2", time: 300, isBreak: true },
      { name: "Math (Calculator)", time: 2700 },
    ],
  },
  {
    name: "Test",
    sections: [
      { name: "English", time: 60 },
      { name: "Math", time: 305 },
      { name: "Break", time: 30, isBreak: true },
      { name: "Reading", time: 60 },
      { name: "Science", time: 120 },
    ],
    isTest: true,
  },
];
